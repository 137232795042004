<template>
    <div class="entry-page full-height full-width">
        <div class="dialog-error error px-4" :class="{ active: error }">
            <div class="text d-flex align-center">
                <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                <b class="white--text">{{ errorMessage }}</b>
            </div>

            <v-btn x-small icon fab color="white" @click="error = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>

        <div class="overflow-y-scroll full-height full-width p-relative">
            <template v-if="loading">
                <v-container fluid class="pa-6 full-height full-width">
                    <div class="full-height full-width d-flex align-center justify-center">
                        <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-container>
            </template>

            <template v-else>
                <v-container fluid class="pa-6" style="max-width: 768px" v-if="entry && entry._id">
                    <v-row>
                        <v-col class="d-flex align-center justify-space-between" cols="12">
                            <template v-if="image">
                                <v-img contain class="full-width full-height cursor-pointer" :src="$fluro.asset.imageUrl(image)">
                                    <template v-slot:placeholder>
                                        <v-row class="fill-height grey ma-0" align="center" justify="center">
                                            <v-progress-circular :width="2" :size="20" color="dark" indeterminate></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </template>

                            <template v-else>
                                <v-row class="fill-height full-width grey ma-0" align="center" justify="center">
                                    <v-icon color="dark-grey" x-large>mdi-image-outline</v-icon>
                                </v-row>
                            </template>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </div>
    </div>
</template>

<script>
import Entry from "@/modules/app-module/entry";

export default {
    name: "entry",

    meta: {
        title: "Entry",
    },

    props: {},

    components: {},

    data() {
        return {
            loading: false,
            error: false,
            errorMessage: "",
            entry: {},
        };
    },

    computed: {
        image() {
            let entry = this.entry;
            return entry?.data?.image || null;
        },
    },

    methods: {
        async init() {
            this.loading = true;

            this.entry = await Entry.get(this.$route.params.id, { params: { appendPosts: "all" } }).then(({ data }) => data);
            console.log("ENTRY", this.entry);

            this.loading = false;
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss"></style>
